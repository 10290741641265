<template>
  <div class="container">
    <h2 class="text-uppercase mega-font">
      We've visited
      <span class="trilon-gradient">15</span>
      countries with our lectures
    </h2>

    <button
      v-scroll-to="{ el: '#trilon-conference-talks', offset: 135 }"
      class="btn btn-primary"
      aria-label="Watch some of them"
    >
      Watch some of them
      <arrow class="arrow-down"></arrow>
    </button>
  </div>
</template>

<script>
import Arrow from '~/components/Arrow.vue';
import NoSSR from 'vue-no-ssr';

export default {
  components: {
    Arrow,
  },
  computed: {},
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss"></style>
