<template>
  <div>
    <br />
    <div id="trilon-conference-talks" class="video-wrapper">
      <div v-for="talk in talks" :key="talk.id">
        <div v-if="talk.id === currentTalk">
          <h6 class="gray">{{ talk.name }} | {{ talk.conference }}</h6>
          <h3 class="talk-title" v-html="talk.talkName"></h3>

          <div class="video-player">
            <ClientOnly>
              <!-- Placeholder during SSR -->
              <!-- @TODO Fix?
               <img
                slot="placeholder"
                src="/assets/kamil-mysliwiec-jscamp.png"
                alt="Trilon - Conference Talk"
              /> -->

              <!-- Default image before iFrame loads -->
              <!-- <div v-show="!iFrameLoaded">
                <img :src="talk.thumbnail"  :alt="'Trilon - ' + talk.name + ' ' + talk.conference">
              </div>-->

              <!-- Conference video -->
              <!-- class="hide"
              v-bind:class="{ 'show': iFrameLoaded}"-->
              <!-- @load="load" -->
              <iframe
                :src="'https://www.youtube.com/embed/' + talk.youtubeId"
                :width="width"
                :height="height"
                :title="talk.talkName"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </ClientOnly>
          </div>
        </div>
      </div>

      <div class="video-selection-wrapper text-right">
        <arrow
          @click.native="previous($event)"
          class="arrow arrow-left"
        ></arrow>
        <div
          v-for="index in totalTalks"
          :key="index"
          @click="goToTalk(index - 1)"
          class="circle"
          v-bind:class="{ selected: index - 1 === currentTalk }"
        ></div>
        <arrow @click.native="next($event)" class="arrow"></arrow>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '~/components/Arrow.vue';
import NoSSR from 'vue-no-ssr';

export default {
  props: {
    homepage: Boolean,
  },
  components: {
    Arrow,
    'no-ssr': NoSSR,
  },
  computed: {
    totalTalks() {
      return this.$store.conferenceTalks.length;
    },
    talks() {
      return this.$store.conferenceTalks;
    },
    currentTalk() {
      return this.$store.currentTalk;
    },
  },
  watch: {
    currentTalk: function (val) {},
  },
  data() {
    return {
      iFrameLoaded: false,
      width: '100%',
      height: 413,
    };
  },
  methods: {
    load: function () {
      this.iFrameLoaded = true;
    },
    goToTalk: function (index) {
      if (index === this.currentTalk) {
        return;
      }
      this._changeTalk(index);
    },
    previous: function (event) {
      const index =
        this.currentTalk - 1 < 0 ? this.totalTalks - 1 : this.currentTalk - 1;

      this._changeTalk(index);
    },
    next: function (event) {
      const index =
        this.currentTalk + 1 > this.totalTalks - 1 ? 0 : this.currentTalk + 1;

      this._changeTalk(index);
    },
    // Private Methods
    _changeTalk: function (index) {
      this.$store.changeTalk(index);
      this.iFrameLoaded = false;
      this.$scrollTo('#trilon-conference-talks', 500, {
        offset: this.homepage ? 135 : 25,
      });
    },
  },
};
</script>

<style lang="scss">
.hide {
  visibility: hidden;
}
.show {
  visibility: visible;
}

.page-layout {
  .our-talks-wrapper {
    padding-top: 0;

    .video-wrapper {
      padding-top: 4rem;
    }
  }
}

.our-talks-wrapper {
  background: url('../assets/bg-world-map-1.png') no-repeat top center;
  min-height: 600px; //[todo]
  padding-top: 5rem;

  &.our-talks-wrapper--no-bg {
    background: none;
  }

  @media (max-width: 1200px) {
    background-size: contain;
  }

  .video-wrapper {
    padding: 10rem 0 6rem;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;

    .talk-title {
      min-height: 60px;
    }

    .video-player {
      margin: 3rem 0 0;
      min-height: 425px;

      background: url('../assets/trilon-logo.svg') no-repeat no-repeat center
        center;
      background-size: contain;

      img {
        max-width: 100%;
      }
    }

    .video-selection-wrapper {
      // [todo]
      margin: 3rem 1.5rem 0;

      * {
        vertical-align: middle;
        cursor: pointer;
      }
      .fas {
        position: relative;
        top: -3px;
        color: #b1b1b1;

        &.fa-arrow-left {
          margin-right: 2rem;
        }
        &.fa-arrow-right {
          margin-left: 2rem;
        }
      }

      .circle {
        background: #302641;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-block;
        margin: 0 0.25rem;

        &:hover {
          background: #ccc;
        }

        &.selected {
          cursor: initial;
          background: #e91e63;
          &:hover {
            background: #e91e63;
          }
        }
      }
    }
  }
}
.arrow:hover path {
  fill: #e91e63;
}
.arrow-down {
  transform: rotate(90deg);
}
.arrow-left {
  transform: rotate(-180deg);
  margin-right: 1rem;
  margin-left: 0;
}
</style>
